var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { ref: "header", staticClass: "yqg-header" }, [
    _c("div", { staticClass: "buffer" }),
    _vm._v(" "),
    _vm._m(0),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "container-wrappper" }, [
      _c("div", { staticClass: "container" }, [
        _c("img", {
          staticClass: "logo",
          attrs: { src: require("./img/logo-white.svg") },
        }),
        _vm._v(" "),
        _c("img", {
          staticClass: "active-logo",
          attrs: { src: require("./img/logo.svg") },
        }),
        _vm._v(" "),
        _c("a", { staticClass: "btn tel", attrs: { href: "tel:4000021836" } }, [
          _c("div", { staticClass: "sm-hide" }, [
            _c("p", [_vm._v("客服热线：4000021836")]),
            _vm._v(" "),
            _c("p", [_vm._v("工作时间：9:00~18:00")]),
          ]),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }